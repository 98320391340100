

jQuery(document).ready(function($){
    $(document).on("click", ".btn-menu", function(){
        if($("#nav").hasClass("open")){
            $("html").removeClass("noscroll");
            $("#nav").removeClass("open");
        } else {
            $("#nav").addClass("open");
            $("html").addClass("noscroll");
        }
        return false;
    });
    
    $(document).on("click", function(e){
        if($("#nav").hasClass("open")){
            if($(e.target).parents(".content-menu").length == 0 && !$(e.target).hasClass("content-menu") && $(e.target).parents(".lang").length == 0){
                $("html").removeClass("noscroll");
                $("#nav").removeClass("open");
            }
        }

        if($("#nav .lang .content ul").hasClass("open")){
            if($(e.target).parents(".lang").length == 0){
                $("#nav .lang .content ul").removeClass("open");
            }
        }
    });

    $(document).on("click", "#nav .close-menu", function(e){
        $("html").removeClass("noscroll");
        $("#nav").removeClass("open");
    });

    $(document).on("focus", ".form-email input", function(){
        $(this).parents(".form-email").addClass("has-focus")
    });

    $(document).on("blur", ".form-email input", function(){
        if($(this).val()==""){
            $(this).parents(".form-email").removeClass("has-focus")
        }
    });

    $(document).on("click", "#info .open-slider", function () {  
        $(this).siblings(".slider").addClass("open");
    });

    $(document).on("click", "#info .slider .btn-close", function () {  
        $(this).parent().removeClass("open");
    });

    $(document).on("click", "#info .rendez .btn-close", function () {  
        $(this).parents(".rendez").removeClass("open");
        $("#info .slider").addClass("open");
    });

    $(document).on("click", "#info .slider .item", function () {  
        if($(this).data("open")){
            $("#info ."+$(this).data("open")).addClass("open");
            $("#info .slider").removeClass("open");
        }
    });

    $(document).on('click', "#nav .content-menu .menu > li > a", function () { 
        if($(this).parent().hasClass("open") || $(this).parent().hasClass("active")){
            $(this).parent().removeClass("active open");
        } else {
            $(this).parent().siblings().removeClass("active open");
            $(this).parent().addClass("open")
        }
    });

    
    $(document).on("click", "#nav a", function(e){
        e.preventDefault();
        if($(this).data("href")){
            localStorage.data= "processus";
            location.pathname = $(this).attr("href");
        } else {
            localStorage.removeItem("data")
            if($(this).attr("href") != "javascript:void(0);" && $(this).attr("href") != "javascript:void(0)" ){
                location.pathname = $(this).attr("href");
            }
        }
    });
    
    $("#nav .menu a[href='"+location.pathname+"']").addClass("active").parents(".menu > li").addClass("active");

    if(localStorage.data){
        $("#nav .menu a[href='"+location.pathname+"']").first().removeClass("active")
        $("html,body").animate({
            scrollTop: $("#processus").offset().top - $("#nav").innerHeight()
        }, 500);
    } else {
        if($("#nav .menu a[href='"+location.pathname+"']").length > 1){
            $("#nav .menu a[href='"+location.pathname+"']").last().removeClass("active")
        }
    }

    $(document).on("click", "#nav .lang .content ul li.active", function(){
        if($(this).parent().hasClass("open")){
            $(this).parent().removeClass("open");
        } else {
            $(this).parent().addClass("open");
        }
    });
});
